/* Wrapper styling for better spacing */

/* Styling the filter section */
.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-section label {
  font-size: 16px;
  margin-right: 10px;
}

.filter-section input[type="date"] {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

.filter-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-buttons button:hover {
  background-color: #0056b3;
}

/* Table Container Styling */
.table-container {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

/* Header Styling */
.table-container th {
  background-color: #343a40;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

/* Alternating row colors for readability */
.table-container tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Currency/Amount and Lab Name Specific Styling */
.table-container td.amount {
  text-align: right;
  font-weight: bold;
}

.table-container td.lab-name {
  font-weight: bold;
  color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .filter-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .table-container {
    padding: 10px;
  }

  .table-container table,
  .table-container thead,
  .table-container tbody,
  .table-container th,
  .table-container td,
  .table-container tr {
    display: block;
  }

  .table-container th {
    text-align: right;
  }

  .table-container td {
    text-align: right;
    border: none;
  }

  .table-container td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table-container td,
  .table-container th {
    padding: 10px 5px;
  }
}

/* Hover Effects for Better Interactivity */
.table-container tr:hover {
  background-color: #e9ecef;
}
