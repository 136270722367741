/* Modal Styles */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    max-width: 600px;
    border-radius: 5px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Modal Content */
  .modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .modal-content button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #45a049;
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .modal-content {
      width: 80%;
    }
  }
  