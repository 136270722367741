/* App.css */

.sample-info {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
  }
  
  .sample-type {
    margin-bottom: 5px;
  }
  
  .card {
    transition: all 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  